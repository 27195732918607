import { Injectable } from '@angular/core';
import { getFirebaseBackend } from '../../authUtils';
import { User } from '../models/auth.models';
import { catchError, Observable, throwError } from 'rxjs';
import { ConfigService } from 'src/app/core/services/config.service';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { jwtDecode } from 'jwt-decode';
import { error } from 'console';
import { Router } from '@angular/router';

interface JwtPayload {
    user_id: number;
    role_id: number;
    username: string;
    firstname: string;
    lastname: string;
    email: string;
    exp: number;
}

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;

    constructor(private _http: HttpClient,
        private router: Router
    ) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    loginUser(username: string, password: string): Observable<any> {
        const params = {
            user_name: username,
            password: password
        }
        return this._http.post(ConfigService.get('getAuthenticate'), params)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        return throwError('Invalid username or password');
                    }
                    return throwError(error);
                })
            );
    }

    isTokenExpired(token: string): boolean {
        const decodedToken: any = jwtDecode(token);
        const expiryTime = decodedToken.exp * 1000; // Convert to milliseconds
        return expiryTime < Date.now();
    }

    isAuthenticated(): boolean {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            return false; // No token found
        }

        try {
            const decodedToken = jwtDecode<JwtPayload>(token);
            const currentTime = Math.floor(Date.now() / 1000);
            return decodedToken.exp > currentTime;
        } catch (error) {
            console.error('Invalid token', error);
            return false;
        }
    }

    public getCurrentLoggedInUser(): User {
        const token = localStorage.getItem('accessToken');
        const decodedToken = jwtDecode<JwtPayload>(token);
        const user = new User();
        user.username = decodedToken.username;
        user.email = decodedToken.email;
        user.firstname = decodedToken.firstname;
        user.lastname = decodedToken.lastname;
        return user;
    }


    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout(): void {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        this.router.navigate(['/account/login']);
    }
}

