import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../services/auth.service';
import { AuthfakeauthenticationService } from '../services/authfake.service';

import { environment } from '../../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private authenticationService: AuthenticationService, 
        private authfackservice: AuthfakeauthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.defaultauth === 'firebase') {
            const currentUser = this.authenticationService.currentUser();
            if (currentUser && currentUser.token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUser.token}`
                    }
                });
            }
        } else {
            
            if (request.url.includes('/auth/login')) {
                //console.log("Intercepter Auth Login");
                // If the request is for login, bypass the interceptor
                return next.handle(request);
              }

              const token = localStorage.getItem('accessToken');
              if (token && this.authenticationService.isTokenExpired(token)) {
                this.authenticationService.logout();
                return next.handle(request); // Optionally skip the request
              }else{
                
                if (token) {
                    const clonedReq = request.clone({
                      headers: request.headers.set('Authorization', `Bearer ${token}`)
                    });
                    return next.handle(clonedReq);
                  }
              }            
            // const currentUser = this.authfackservice.currentUserValue;
            // if (currentUser && currentUser.token) {
            //     request = request.clone({
            //         setHeaders: {
            //             Authorization: `Bearer ${currentUser.token}`
            //         }
            //     });
            // }
        }
        return next.handle(request);
    }
}
