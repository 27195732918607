import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  static set(property, value) {
    this[property] = value;
  }

  static get(property) {
    return this[property];
  }
}
