import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'uil-home-alt',
        link: '/',
    },   
    {
        id: 2,
        label: 'MENUITEMS.APPS.TEXT',
        icon: 'uil-apps',
        subItems: [
            {
                id: 3,
                label: 'Project',//MENUITEMS.PROJECT.TEXT
                icon: 'uil-comments-alt',
                link: '/project/list',
            },
            {
                id: 4,
                label: 'Client',//MENUITEMS.PROJECT.TEXT
                icon: 'uil-comments-alt',
                link: '/client/list',
            },
            {
                id: 5,
                label: 'Application User',//MENUITEMS.PROJECT.TEXT
                icon: 'uil-comments-alt',
                link: '/user/list',
            },
            {
                id: 6,
                label: 'Company Accounts',//MENUITEMS.PROJECT.TEXT
                icon: 'uil-comments-alt',
                link: '/company-accounts/list',
            },
            {
                id: 7,
                label: 'Admin',
                icon: 'uil-store',
                subItems: [
                    {
                        id: 8,
                        label: 'Channel Partner',//MENUITEMS.PROJECT.TEXT
                        icon: 'uil-comments-alt',
                        link: '/admin/channel-partner',
                    },
                    {
                        id: 9,
                        label: 'Branch',//MENUITEMS.PROJECT.TEXT
                        icon: 'uil-comments-alt',
                        link: '/admin/branch',
                    },
                    // {
                    //     id: 110,
                    //     label: 'City',//MENUITEMS.PROJECT.TEXT
                    //     icon: 'uil-comments-alt',
                    //     link: '/admin/city',
                    // },
                    // {
                    //     id: 111,
                    //     label: 'Country',//MENUITEMS.PROJECT.TEXT
                    //     icon: 'uil-comments-alt',
                    //     link: '/admin/country',
                    // },
                    {
                        id: 10,
                        label: 'CP master',//MENUITEMS.PROJECT.TEXT
                        icon: 'uil-comments-alt',
                        link: '/admin/cp',
                    },
                    // {
                    //     id: 113,
                    //     label: 'Currency Master',//MENUITEMS.PROJECT.TEXT
                    //     icon: 'uil-comments-alt',
                    //     link: '/admin/currency-master',
                    // },
                    {
                        id: 11,
                        label: 'Methods',//MENUITEMS.PROJECT.TEXT
                        icon: 'uil-comments-alt',
                        link: '/admin/methods',
                    },
                    // {
                    //     id: 115,
                    //     label: 'Nationality Master',//MENUITEMS.PROJECT.TEXT
                    //     icon: 'uil-comments-alt',
                    //     link: '/admin/nationality-master',
                    // },
                    // {
                    //     id: 116,
                    //     label: 'Province',//MENUITEMS.PROJECT.TEXT
                    //     icon: 'uil-comments-alt',
                    //     link: '/admin/province',
                    // }
                ]
            },           
        ]
    }   
];

