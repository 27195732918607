import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true
    },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'uil-home-alt',
        badge: {
            variant: 'primary',
            text: '01', //'MENUITEMS.DASHBOARDS.BADGE',
        },
        link: '/',
    },
    // {
    //     id: 3,
    //     isLayout: true
    // },
    {
        id: 4,
        label: 'MENUITEMS.APPS.TEXT',
        isTitle: true
    },
    {
        id: 5,
        label: 'Project',//MENUITEMS.PROJECT.TEXT
        icon: 'uil-list-ul',
        link: '/project/list',
    },
    {
        id: 6,
        label: 'Client',//MENUITEMS.PROJECT.TEXT
        icon: 'uil-users-alt',
        link: '/client/list',
    },
    {
        id: 7,
        label: 'Invite User',//MENUITEMS.PROJECT.TEXT
        icon: 'uil-user-plus',
        link: '/user/list',
    },
    {
        id: 8,
        label: 'Bank',//MENUITEMS.PROJECT.TEXT
        icon: 'bx bxs-bank',
        link: '/company-accounts/list',
    },
    {
        id: 9,
        label: 'Customer',//MENUITEMS.PROJECT.TEXT
        icon: 'uil-users-alt',
        link: '/customer/list',
    },
    {
        id: 15,
        label: 'Deposit',//MENUITEMS.PROJECT.TEXT
        icon: 'uil-money-withdraw',
        link: '/deposit/list',
    },
    {
        id: 16,
        label: 'Transfer',//MENUITEMS.PROJECT.TEXT
        icon: 'bx bx-transfer-alt',
        link: '/transfer/list',
    },
    {
        id: 17,
        label: 'Withdrawal',//MENUITEMS.PROJECT.TEXT
        icon: 'uil-money-withdrawal',
        link: '/withdrawal/list',
    },
    {
        id: 17,
        label: 'Claim Invoice',//MENUITEMS.PROJECT.TEXT
        icon: 'uil-invoice',
        link: '/claimInvoice/list',
    },
    {
        id: 10,
        label: 'Admin',
        icon: 'uil-store',
        subItems: [
            {
                id: 11,
                label: 'Channel Partner',//MENUITEMS.PROJECT.TEXT
                icon: 'uil-comments-alt',
                link: '/admin/channel-partner',
            },
            {
                id: 12,
                label: 'Branch',//MENUITEMS.PROJECT.TEXT
                icon: 'uil-comments-alt',
                link: '/admin/branch',
            },
            // {
            //     id: 110,
            //     label: 'City',//MENUITEMS.PROJECT.TEXT
            //     icon: 'uil-comments-alt',
            //     link: '/admin/city',
            // },
            // {
            //     id: 111,
            //     label: 'Country',//MENUITEMS.PROJECT.TEXT
            //     icon: 'uil-comments-alt',
            //     link: '/admin/country',
            // },
            {
                id: 13,
                label: 'CP master',//MENUITEMS.PROJECT.TEXT
                icon: 'uil-comments-alt',
                link: '/admin/cp',
            },
            // {
            //     id: 113,
            //     label: 'Currency Master',//MENUITEMS.PROJECT.TEXT
            //     icon: 'uil-comments-alt',
            //     link: '/admin/currency-master',
            // },
            {
                id: 14,
                label: 'Methods',//MENUITEMS.PROJECT.TEXT
                icon: 'uil-comments-alt',
                link: '/admin/methods',
            },

            // {
            //     id: 115,
            //     label: 'Nationality Master',//MENUITEMS.PROJECT.TEXT
            //     icon: 'uil-comments-alt',
            //     link: '/admin/nationality-master',
            // },
            // {
            //     id: 116,
            //     label: 'Province',//MENUITEMS.PROJECT.TEXT
            //     icon: 'uil-comments-alt',
            //     link: '/admin/province',
            // }
        ]
    },

];

