<!-- Right Sidebar -->
<div class="right-bar">

  <ngx-simplebar class="h-100">
    <div class="rightbar-title px-3 py-4">
      <a href="javascript:void(0);" class="right-bar-toggle float-end" (click)="hide();">
        <i class="mdi mdi-close noti-icon"></i>
      </a>
      <h5 class="m-0">Settings</h5>
    </div>

    <div class="p-4">
      <h6 class="mb-3">Layout</h6>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="layout" id="layout-vertical" value="vertical" [checked]="layout === 'vertical'" (change)="changeLayout('vertical')">
        <label class="form-check-label" for="layout-vertical">Vertical</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="layout" id="layout-horizontal" value="horizontal" [checked]="layout === 'horizontal'" (change)="changeLayout('horizontal')">
        <label class="form-check-label" for="layout-horizontal">Horizontal</label>
      </div>

      <h6 class="mt-4 mb-3 pt-2">Layout Mode</h6>

      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="layout-mode" id="layout-mode-light" value="light" [checked]="mode === 'light'" (change)="changeMode('light')">
        <label class="form-check-label" for="layout-mode-light">Light</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="layout-mode" id="layout-mode-dark" value="dark" [checked]="mode === 'dark'" (change)="changeMode('dark')">
        <label class="form-check-label" for="layout-mode-dark">Dark</label>
      </div>

      <h6 class="mt-4 mb-3 pt-2">Layout Width</h6>

      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="layout-width" id="layout-width-fuild" value="fuild" [checked]="width === 'fluid'" (change)="changeWidth('fluid')">
        <label class="form-check-label" for="layout-width-fuild">Fluid</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="layout-width" id="layout-width-boxed" value="boxed" [checked]="width === 'boxed'" (change)="changeWidth('boxed')">
        <label class="form-check-label" for="layout-width-boxed">Boxed</label>
      </div>

      <h6 class="mt-4 mb-3 pt-2">Topbar Color</h6>

      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="topbar-color" id="topbar-color-light" value="light" [checked]="topbar === 'light'" (change)="changeTopbar('light')">
        <label class="form-check-label" for="topbar-color-light">Light</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="topbar-color" id="topbar-color-dark" value="dark" [checked]="topbar === 'dark'" (change)="changeTopbar('dark')">
        <label class="form-check-label" for="topbar-color-dark">Dark</label>
      </div>

      <h6 class="mt-4 mb-3 pt-2 sidebar-setting">Sidebar Size</h6>

      <div class="form-check sidebar-setting">
        <input class="form-check-input" type="radio" name="sidebar-size" id="sidebar-size-default" value="default" [checked]="sidebarsize === 'lg'" (change)="changeSidebarSize('lg')">
        <label class="form-check-label" for="sidebar-size-default">Default</label>
      </div>
      <div class="form-check sidebar-setting">
        <input class="form-check-input" type="radio" name="sidebar-size" id="sidebar-size-compact" value="compact" [checked]="sidebarsize === 'small'" (change)="changeSidebarSize('small')">
        <label class="form-check-label" for="sidebar-size-compact">Compact</label>
      </div>
      <div class="form-check sidebar-setting">
        <input class="form-check-input" type="radio" name="sidebar-size" id="sidebar-size-small" value="small" [checked]="sidebarsize === 'sm'" (change)="changeSidebarSize('sm')">
        <label class="form-check-label" for="sidebar-size-small">Small (Icon View)</label>
      </div>

      <h6 class="mt-4 mb-3 pt-2 sidebar-setting">Sidebar Color</h6>

      <div class="form-check sidebar-setting">
        <input class="form-check-input" type="radio" name="sidebar-color" id="sidebar-color-light" value="light" [checked]="sidebarcolor === 'light'" (change)="changeSidebarColor('light')">
        <label class="form-check-label" for="sidebar-color-light">Light</label>
      </div>
      <div class="form-check sidebar-setting">
        <input class="form-check-input" type="radio" name="sidebar-color" id="sidebar-color-dark" value="dark" [checked]="sidebarcolor === 'dark'" (change)="changeSidebarColor('dark')">
        <label class="form-check-label" for="sidebar-color-dark">Dark</label>
      </div>
      <div class="form-check sidebar-setting">
        <input class="form-check-input" type="radio" name="sidebar-color" id="sidebar-color-colored" value="colored" [checked]="sidebarcolor === 'Colored'" (change)="changeSidebarColor('Colored')">
        <label class="form-check-label" for="sidebar-color-colored">Colored</label>
      </div>

    </div>
  </ngx-simplebar>
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<div class="rightbar-overlay"></div>